export const flightData = [
  {
    date: "2024-01-02",
    startTime: "00:44",
    endTime: "04:33",
    flightNumber: "FL-1",
    from: "ZLP",
    to: "ODS",
    id: "123",
  },
]
