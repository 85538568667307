import { SeparatorWithChildren } from "@fourel/ui"

import type { QuoteLeg } from "#components/offers/models/offer-types.js"
import { getTimeDifference } from "#pages/offers/preview-offer/helpers/index.js"

export const LegDetails = ({ leg }: { leg: QuoteLeg }) => {
  return (
    <div className="flex flex-col gap-2 bg-purple-600/20 px-2 py-4 dark:bg-purple-600/40">
      <div className="flex items-center justify-between gap-2">
        <div className="flex w-1/3 flex-col items-center">
          <span className="text-xs text-slate-500 dark:text-slate-200">Date</span>
          <span>{new Date(leg.departureDate).toISOString().slice(0, 10)}</span>
        </div>
        <div className="flex w-1/3 flex-col items-center">
          <span className="text-xs text-slate-500 dark:text-slate-200">
            Departure Airport
          </span>
          <span>{leg.departureAirport.icaoCode || leg.departureAirport.iataCode}</span>
        </div>
        <div className="flex w-1/3 flex-col items-center">
          <span className="text-xs text-slate-500 dark:text-slate-200">
            Arrival Airport
          </span>
          <span>{leg.arrivalAirport.icaoCode || leg.arrivalAirport.iataCode}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-2">
        <div className="flex w-1/3 flex-col items-center">
          <span className="text-xs text-slate-500 dark:text-slate-200">
            Departure Time
          </span>
          <span>{leg.estimatedTimeOfDeparture.slice(0, 5)}</span>
        </div>
        <div className="w-1/3">
          <div className="text-center text-xs text-slate-500 dark:text-slate-200">
            Flight Time
          </div>
          <SeparatorWithChildren className="w-full bg-violet-700">
            {getTimeDifference({
              endTime: leg.estimatedTimeOfArrival,
              startTime: leg.estimatedTimeOfDeparture,
              startDateTime: leg.departureDate,
              endDateTime: leg.arrivalDate,
            })}
          </SeparatorWithChildren>
        </div>
        <div className="flex w-1/3 flex-col items-center">
          <span className="text-xs text-slate-500 dark:text-slate-200">Arrival Time</span>
          <span>{leg.estimatedTimeOfArrival.slice(0, 5)}</span>
        </div>
      </div>
    </div>
  )
}
