export const formatDate = (dateString: string | Date | null): string => {
  if (!dateString) return "No date"

  const date = new Date(dateString)

  if (isNaN(date.getTime())) {
    return "Invalid date"
  }

  if (dateString instanceof Date) {
    return formatCustomDate(dateString)
  }

  return formatCustomDate(date)
}

const formatCustomDate = (date: Date): string => {
  const day = date.getUTCDate()
  const month = date.toLocaleString("en-GB", { month: "short" }).toUpperCase()
  const year = date.getUTCFullYear()

  return `${day} ${month} ${year}`
}

export const formatTime = (timeString: string): string => {
  const [hours, minutes] = timeString.split(":")
  return `${hours}:${minutes}`
}
