interface Event {
  id: string
  start: number
  end: number
  from: string
  to: string
  flightNumber: string
}

interface DayData {
  weekday: string
  date: Date
  events: Event[]
}

export interface FlightEvent {
  date: string
  startTime: string
  endTime: string
  from: string
  to: string
  flightNumber: string
  id: string
}

const convertTimeToHour = (time: string): number => {
  const [hours, minutes] = time.split(":").map(Number)
  return hours + minutes / 60
}

export const convertEventsData = (events: FlightEvent[]): DayData[] => {
  const dayDataMap: Record<string, DayData> = {}

  events.forEach((event) => {
    const date = new Date(event.date)
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]
    const startHour = convertTimeToHour(event.startTime)
    const endHour = convertTimeToHour(event.endTime)

    const isEventEndingAtMidnight = endHour === 0

    if (isEventEndingAtMidnight && startHour <= 23.999) {
      const eventData: Event = {
        id: `${event.date}-${event.startTime}-${event.endTime}`,
        start: startHour,
        end: 23.9833,
        from: event.from,
        to: event.to,
        flightNumber: event.flightNumber,
      }

      if (!dayDataMap[event.date]) {
        dayDataMap[event.date] = { weekday, date, events: [eventData] }
      } else {
        dayDataMap[event.date].events?.push(eventData)
      }
    } else if (endHour < startHour) {
      const eventPart1: Event = {
        id: `${event.date}-${event.startTime}-part1`,
        start: startHour,
        end: 24,
        from: event.from,
        to: event.to,
        flightNumber: event.flightNumber,
      }

      if (!dayDataMap[event.date]) {
        dayDataMap[event.date] = { weekday, date, events: [eventPart1] }
      } else {
        dayDataMap[event.date].events?.push(eventPart1)
      }

      const nextDayDate = new Date(date)
      nextDayDate.setDate(nextDayDate.getDate() + 1)
      const nextDayStr = nextDayDate.toISOString().split("T")[0]
      const eventPart2: Event = {
        id: `${nextDayStr}-${event.endTime}-part2`,
        start: 0,
        end: endHour,
        from: event.from,
        to: event.to,
        flightNumber: event.flightNumber,
      }

      if (!dayDataMap[nextDayStr]) {
        dayDataMap[nextDayStr] = {
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
            nextDayDate.getDay()
          ],
          date: nextDayDate,
          events: [eventPart2],
        }
      } else {
        dayDataMap[nextDayStr].events?.push(eventPart2)
      }
    } else {
      const eventData: Event = {
        id: `${event.date}-${event.startTime}-${event.endTime}`,
        start: startHour,
        end: endHour,
        from: event.from,
        to: event.to,
        flightNumber: event.flightNumber,
      }

      if (!dayDataMap[event.date]) {
        dayDataMap[event.date] = { weekday, date, events: [eventData] }
      } else {
        dayDataMap[event.date].events?.push(eventData)
      }
    }
  })

  return Object.values(dayDataMap)
}
