import { Link, useRouterState } from "@tanstack/react-router"
import ChevronRightIcon from "lucide-static/icons/chevron-right.svg"
import Clipboard from "lucide-static/icons/clipboard.svg"
import CompassIcon from "lucide-static/icons/compass.svg"
import CreditCardIcon from "lucide-static/icons/credit-card.svg"
import DashboardIcon from "lucide-static/icons/layout-dashboard.svg"
import MegaphoneIcon from "lucide-static/icons/megaphone.svg"
import PlaneIcon from "lucide-static/icons/plane.svg"
import PlusIcon from "lucide-static/icons/plus.svg"
import SettingsIcon from "lucide-static/icons/settings.svg"
import UserRoundIcon from "lucide-static/icons/user-round.svg"
import WalletMinimal from "lucide-static/icons/wallet-2.svg"
import InlineSVG from "react-inlinesvg"

import { cn, Command, CommandGroup, CommandItem, CommandList } from "@fourel/ui"

import { AddMember } from "#components/add-member.js"
import { FeaturesConfig } from "#feature-gate/features.js"
import { useOnboardedUserInfo } from "#store/user-info.js"

export const menuItems = [
  {
    icon: UserRoundIcon,
    label: "Clients",
    url: "/$slug/clients",
  },
  {
    icon: CompassIcon,
    label: "Trip requests",
    url: "/$slug/trip-requests",
  },
  {
    icon: Clipboard,
    label: "Quotations",
    url: "quotations",
  },
  {
    icon: WalletMinimal,
    label: "Offers",
    url: "/$slug/offers",
  },
  FeaturesConfig.AdvertiseRoute && {
    icon: MegaphoneIcon,
    label: "Advertise",
    url: "/$slug/advertise",
  },
  FeaturesConfig.FlightCalendarRoute && {
    icon: PlaneIcon,
    label: "Flights",
    url: "flights",
  },
  FeaturesConfig.DashboardRoute && {
    icon: DashboardIcon,
    label: "Dashboard",
    url: "dashboard",
  },
  FeaturesConfig.PaymentsRoute && {
    icon: CreditCardIcon,
    label: "Payments",
    url: "payments",
  },
  {
    icon: PlusIcon,
    label: "App Integration",
    url: "integration",
  },
].filter(Boolean)

const commandInputStyles =
  "mb-2 cursor-pointer gap-2 p-2 text-violet-500  aria-selected:text-violet-500 aria-selected:bg-violet-700 aria-selected:hover:bg-violet-800 aria-selected:hover:text-white aria-selected:transition-all aria-selected:duration-200 aria-selected:ease-in-out"

export function MenuTabs({ open }: { open: boolean }) {
  const { currentOrg } = useOnboardedUserInfo()
  const pathname = useRouterState({
    select: (s) => s.location.pathname,
  })
  let menuCategoryName = "no match"
  if (currentOrg && currentOrg.slug && pathname) {
    const menuCategoryRegex = new RegExp(`/${currentOrg.slug}/([^/]+)`)
    const menuCategoryMatch = pathname.match(menuCategoryRegex)
    menuCategoryName = menuCategoryMatch ? menuCategoryMatch[1] : "no match"
  }

  return (
    <div className="flex flex-auto overflow-hidden text-sm">
      <Command className="static w-full flex-auto overflow-y-auto bg-transparent">
        <CommandList className="flex flex-auto flex-col overflow-y-auto [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
          <CommandGroup className={cn("p-0", !open && "mr-4")}>
            {menuItems.map((item) => (
              <Link
                to={item.url}
                key={item.label}
                from="/$slug"
                params={{ slug: currentOrg.slug }}
              >
                <CommandItem
                  className={cn(
                    commandInputStyles,
                    !open && "mx-auto w-fit",
                    item.url.includes(menuCategoryName)
                      ? "bg-violet-900 text-white aria-selected:bg-violet-900 aria-selected:text-white"
                      : "",
                  )}
                >
                  <InlineSVG src={item.icon} className="h-5 w-5 min-w-5 text-white" />
                  <span
                    className={cn(
                      "whitespace-nowrap text-white",
                      !open && "hidden text-[0px]",
                    )}
                  >
                    {item.label}
                  </span>
                  <InlineSVG
                    src={ChevronRightIcon}
                    className={cn("ml-auto h-5 w-5 min-w-5", !open && "hidden")}
                  />
                </CommandItem>
              </Link>
            ))}
          </CommandGroup>
          <CommandGroup className={cn("bottom-4 mt-auto p-0", !open && "mr-4")}>
            <CommandItem className={cn(commandInputStyles, !open && "mx-auto w-fit")}>
              <AddMember open={open} />
            </CommandItem>

            <CommandItem className={cn(commandInputStyles, !open && "mx-auto w-fit")}>
              <Link
                className="flex w-full items-center gap-2"
                to="/$slug/settings/members"
                params={{ slug: currentOrg.slug }}
              >
                <InlineSVG src={SettingsIcon} className="h-4 w-4 min-w-5 text-white" />
                <span
                  className={cn(
                    "whitespace-nowrap text-white",
                    !open && "hidden text-[0px]",
                  )}
                >
                  Settings
                </span>
              </Link>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}
