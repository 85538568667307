import React from "react"

import { cn, SeparatorCustom, Tooltip, TooltipContent, TooltipTrigger } from "@fourel/ui"

import type { EventSlotProps } from "#components/calendar/components/calendar-component.js"
import {
  HOUR_WIDTH,
  MINUTES_IN_HOUR,
} from "#components/calendar/components/calendar-component.js"

export const EventSlot = ({
  events,
  hour,
  eventIndices,
  dayEventsLength,
}: EventSlotProps) => {
  return (
    <div
      style={{
        height: dayEventsLength > 4 ? `${dayEventsLength * 25}px` : "100px",
      }}
      className={cn(
        "relative border-b border-t border-gray-300 dark:border-gray-600",
        `${
          hour % 2 === 0
            ? "border-l border-gray-300 dark:border-gray-600"
            : "border-r border-gray-300 dark:border-gray-600"
        }`,
      )}
    >
      {events.map((event) => {
        const eventIndex = eventIndices.find(({ event: e }) => e.id === event.id)?.index
        const eventHeight = dayEventsLength <= 3 ? 33 : 25
        const top = `${eventIndex !== undefined ? eventIndex * eventHeight : 0}px`

        let left
        let width
        const isOvernightEvent = event.start < 24 && event.end >= 24

        if (isOvernightEvent) {
          if (hour <= 24 && hour + 1 > 24) {
            const startMinutes = (event.start - hour) * MINUTES_IN_HOUR
            left = `${startMinutes * (HOUR_WIDTH / MINUTES_IN_HOUR)}px`
            const durationMinutesFirstDay = (24 - event.start) * MINUTES_IN_HOUR
            width = `${durationMinutesFirstDay * (HOUR_WIDTH / MINUTES_IN_HOUR)}px`
          } else if (hour === 0) {
            const durationMinutesFirstDay = (24 - event.start) * MINUTES_IN_HOUR
            const widthFirstDay = durationMinutesFirstDay * (HOUR_WIDTH / MINUTES_IN_HOUR)
            left = `-${widthFirstDay}px`
            const durationMinutesNextDay = event.end * MINUTES_IN_HOUR
            width = `${durationMinutesNextDay * (HOUR_WIDTH / MINUTES_IN_HOUR)}px`
          }
        } else {
          const startMinutes = (event.start - hour) * MINUTES_IN_HOUR
          left = `${startMinutes * (HOUR_WIDTH / MINUTES_IN_HOUR)}px`
          const durationMinutes = (event.end - event.start) * MINUTES_IN_HOUR
          width = `${durationMinutes * (HOUR_WIDTH / MINUTES_IN_HOUR)}px`
        }

        return (
          <div
            key={event.id}
            style={{ top, left, width, height: dayEventsLength <= 3 ? "33px" : "25px" }}
            className="z-1 absolute flex items-center overflow-hidden rounded-md bg-violet-800/10 px-2 hover:bg-violet-800/40 dark:bg-violet-800/60"
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="relative z-10 flex w-full flex-nowrap items-center justify-between gap-3 text-xs">
                  <span className="text-nowrap">{event.flightNumber}</span>
                  <div className="flex flex-1 items-center justify-center gap-1">
                    <span className="text-center">{event.from}</span>
                    <SeparatorCustom className="bg-violet-800 dark:bg-gray-100" />
                    <span className="text-center">{event.to}</span>
                  </div>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="flex w-40 flex-col gap-2">
                  Flight Number: {event.flightNumber}
                  <div className="flex flex-col gap-1">
                    <span>From: {event.from}</span>
                    <span>To: {event.to}</span>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}
