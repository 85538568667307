import { Route } from "@tanstack/react-router"

import { Calendar } from "#components/calendar/calendar.js"
import { clientsRoute } from "#pages/clients/index.js"

import { flightData } from "./data.js"

export const Flights = () => {
  return <Calendar events={flightData} />
}

export const clientsFlightsRoute = new Route({
  getParentRoute: () => clientsRoute,
  path: "$client/flights",
  component: Flights,
})
