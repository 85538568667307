import type { ReactElement } from "react"
import { useEffect, useMemo, useState } from "react"

import type { StatusType } from "#components/offers/models/offer-types.js"

type TimeLeft = {
  hours: string
  minutes: string
  seconds: string
}

const calculateTimeLeft = (expDate: string) => {
  const now = new Date()
  const exp = new Date(expDate)
  const difference = exp.getTime() - now.getTime()

  if (difference <= 0) {
    return "expired"
  }
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((difference / (1000 * 60)) % 60)
  const seconds = Math.floor((difference / 1000) % 60)

  const padWithZero = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`
  }

  const formattedHours = padWithZero(hours)
  const formattedMinutes = padWithZero(minutes)
  const formattedSeconds = padWithZero(seconds)

  return { hours: formattedHours, minutes: formattedMinutes, seconds: formattedSeconds }
}

const statusRenderers: {
  [key in StatusType]: (timeLeft?: TimeLeft | string) => ReactElement
} = {
  approved: () => (
    <span className="block rounded-md bg-violet-800/20 px-2 py-1 text-xs text-black dark:bg-violet-700 dark:text-gray-100">
      Approved
    </span>
  ),
  pending: (timeLeft?: TimeLeft | string) => (
    <span className="text-xs text-black/60">
      Pending time{" "}
      {typeof timeLeft === "string" ? (
        "expired"
      ) : (
        <span className="max-w-20 text-black">
          {`${timeLeft?.hours}:${timeLeft?.minutes}:${timeLeft?.seconds}`}
        </span>
      )}
    </span>
  ),
  signed: () => (
    <span className="block rounded-md bg-violet-800/80 px-2 py-1 text-xs text-white dark:bg-violet-500">
      Signed
    </span>
  ),
  cancelled: () => (
    <span className="block rounded-md bg-red-500/80 px-2 py-1 text-xs text-white">
      Cancelled
    </span>
  ),
  expired: () => (
    <span className="text-xs">
      Pending time: <span className="text-red-500">Expired</span>
    </span>
  ),
}

const renderStatus = (status: StatusType, timeLeft?: TimeLeft | string): ReactElement => {
  const renderFunction = statusRenderers[status]
  if (renderFunction) {
    return renderFunction(timeLeft)
  }
  return <span>{status}</span>
}

export const OfferStatus = ({
  status,
  pendingTime,
}: {
  status: StatusType
  pendingTime?: string
}) => {
  const memoizedTimeLeft = useMemo(() => {
    return pendingTime ? calculateTimeLeft(pendingTime) : ""
  }, [pendingTime])

  const [timeLeft, setTimeLeft] = useState<TimeLeft | string>(memoizedTimeLeft)

  useEffect(() => {
    if (!pendingTime) {
      return
    }

    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(pendingTime))
    }, 1000)

    // eslint-disable-next-line consistent-return -- this is a cleanup function
    return () => clearInterval(intervalId)
  }, [pendingTime])

  return renderStatus(status, timeLeft)
}
