import React from "react"

import { cn } from "@fourel/ui"

import { EventSlot } from "#components/calendar/components/event-slot.js"
import { TimeLine } from "#components/calendar/components/time-line.js"

export const HOUR_WIDTH = 50
export const HOURS_IN_DAY = 24
export const MINUTES_IN_HOUR = 60

const hours = Array.from({ length: HOURS_IN_DAY }, (_, index) => index)

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

export interface Event {
  id: string
  start: number
  end: number
  from: string
  to: string
  flightNumber: string
}

export interface DayData {
  date: Date
  events?: Event[]
  weekday: string
}

interface CalendarProps {
  referenceDate: Date
  eventsData: DayData[]
}

interface EventIndex {
  event: Event
  index: number
}

export interface EventSlotProps {
  events: Event[]
  hour: number
  eventIndices: EventIndex[]
  dayEventsLength: number
}

export const CalendarComponent = ({ referenceDate, eventsData }: CalendarProps) => {
  const startOfMonth = new Date(referenceDate)
  startOfMonth.setDate(1)
  const endOfMonth = new Date(referenceDate)
  endOfMonth.setMonth(endOfMonth.getMonth() + 1)
  endOfMonth.setDate(0)

  const calculateStartOfWeek = (date: Date): Date => {
    const dayOfWeek = date.getDay()
    const diff = (dayOfWeek + 6) % 7
    const startOfWeek = new Date(date)
    startOfWeek.setDate(startOfWeek.getDate() - diff)
    return startOfWeek
  }

  const weeksInMonth = []
  let currentWeek = []
  const currentDate = calculateStartOfWeek(startOfMonth)
  while (currentDate <= endOfMonth) {
    currentWeek.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
    if (currentDate.getDay() === 0 || currentDate > endOfMonth) {
      weeksInMonth.push(currentWeek)
      currentWeek = []
    }
  }

  return (
    <div
      style={{
        gridTemplateColumns: `60px repeat(${HOURS_IN_DAY}, ${HOUR_WIDTH}px)`,
        overflowY: "auto",
      }}
      className="relative grid h-[calc(100vh-180px)] overflow-hidden"
    >
      <TimeLine hours={hours} />
      {weeksInMonth.map((week, weekIndex) => (
        <div
          key={`week-${weekIndex}`}
          style={{
            gridRow: weekIndex + 2,
            gridTemplateColumns: `60px repeat(${HOURS_IN_DAY}, ${HOUR_WIDTH}px)`,
          }}
          className="grid"
        >
          {week.map((day, dayIndex) => (
            <React.Fragment key={`day-${weekIndex}-${dayIndex}`}>
              <div className="flex flex-col items-center justify-center">
                {day && (
                  <>
                    <span className={cn(day.getDay() === 1 && "text-violet-800")}>
                      {weekdays[day.getDay() === 0 ? 6 : day.getDay() - 1]}
                    </span>
                    <span className={cn(day.getDay() === 1 && "text-violet-800")}>
                      {day.getDate()}
                    </span>
                  </>
                )}
              </div>
              {hours.map((hour) => {
                const dayEvents =
                  eventsData.find(
                    (eventData) => eventData.date.toDateString() === day.toDateString(),
                  )?.events || []

                const eventsInThisHour = dayEvents.filter(
                  (event) => event.start >= hour && event.start < hour + 1,
                )
                const eventIndices = dayEvents.map((event, index) => ({
                  event,
                  index,
                }))
                return (
                  <EventSlot
                    key={`hour-${hour}-${dayIndex}`}
                    events={eventsInThisHour}
                    hour={hour}
                    eventIndices={eventIndices}
                    dayEventsLength={dayEvents.length}
                  />
                )
              })}
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  )
}
