import ArrowDown from "lucide-static/icons/arrow-down.svg"
import ArrowUp from "lucide-static/icons/arrow-up.svg"
import React, { useState } from "react"
import InlineSVG from "react-inlinesvg"

import { Button } from "@fourel/ui"

import { CalendarComponent } from "#components/calendar/components/calendar-component.js"
import { convertEventsData, type FlightEvent } from "#components/calendar/helpers.js"

export const Calendar = ({ events }: { events: FlightEvent[] }) => {
  const [referenceDate, setReferenceDate] = useState(new Date())

  const eventsDataMapped = convertEventsData(events)

  const goToPreviousMonth = () => {
    const newDate = new Date(referenceDate)
    newDate.setMonth(newDate.getMonth() - 1)
    newDate.setDate(1)
    setReferenceDate(newDate)
  }

  const goToNextMonth = () => {
    const newDate = new Date(referenceDate)
    newDate.setMonth(newDate.getMonth() + 1)
    newDate.setDate(1)
    setReferenceDate(newDate)
  }

  return (
    <div className="relative flex flex-col items-start gap-5">
      <div className="fixed flex items-center justify-center gap-2 pl-3">
        <Button variant="outline" onClick={goToPreviousMonth}>
          <InlineSVG src={ArrowDown} className="size-4" />
        </Button>
        <Button variant="outline" onClick={goToNextMonth}>
          <InlineSVG src={ArrowUp} className="size-4" />
        </Button>
        {referenceDate.toLocaleString("en-EN", { month: "long" })}{" "}
      </div>
      <div className="pt-16">
        <CalendarComponent referenceDate={referenceDate} eventsData={eventsDataMapped} />
      </div>
    </div>
  )
}
