import React from "react"

import { cn } from "@fourel/ui"

import { HOURS_IN_DAY } from "#components/calendar/components/calendar-component.js"

export const TimeLine = ({ hours }: { hours: number[] }) => {
  return (
    <div
      style={{ gridColumn: `2 / span ${HOURS_IN_DAY}` }}
      className="sticky top-0 z-10 flex"
    >
      {hours.map((hour) => (
        <div
          key={hour}
          className={cn(
            "flex w-[100px] items-end justify-start",
            hour % 2 !== 0
              ? "text-xs text-slate-500 dark:text-gray-300"
              : "text-sm text-black dark:text-slate-500",
          )}
        >
          {`${hour.toString().padStart(2, "0")}:00`}
        </div>
      ))}
    </div>
  )
}
